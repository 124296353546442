export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/tiffin-logo1.png',
    banner_img_root : '/img/tiffin-banner.jpg',
    resid : 'tiffins-india-cafe2416-arapahoe-avenueboulder-252',
    key_value : 'tiffinsweb',
    secret_value : 'tiffinsweb',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3055.7489848880687!2d-105.26488818519668!3d40.01405417941402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bedd26590a0a5%3A0xc2371f43f2620d39!2s2416%20Arapahoe%20Ave%2C%20Boulder%2C%20CO%2080302%2C%20USA!5e0!3m2!1sen!2sin!4v1582196682504!5m2!1sen!2sin'
};
